table { border-collapse: collapse; }

td.number {
    text-align: right;
    padding-left: 10px;
    padding-right: 10px;
}

td:nth-of-type(5n), th:nth-of-type(5n), td:last-of-type, th:last-of-type {
    border-right: 2px solid black;
}

td:nth-of-type(1), th:nth-of-type(1) {
    border-left: 2px solid black;
}

tr:nth-of-type(4n), tr:last-of-type {
    border-bottom: 2px solid black;
}

tr:nth-of-type(1) {
    border-top: 2px solid black;
}

tr:last-of-type td:nth-of-type(5n).negative {
    color: red;
    font-weight: bolder;
}

tr:last-of-type td:nth-of-type(5n).positive {
    color: green;
    font-weight: bolder;
}

input[type=number] {
    width: 50px;
    text-align: right;
}